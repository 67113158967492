import axios from "axios";
import { indonesia_payment } from "../../js/path";

const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;

export default {
  data() {
    return {
      pageNo: null,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      currentPage: 1,
      params: "",
      key: 0,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "member",
        },
        {
          key: "usage",
        },
        {
          key: "code",
        },
        {
          key: "value",
        },
      ],
    };
  },
  methods: {
    searchFor() {
      if (this.filter.length > 1) this.fetchData();
      else if (this.filter.length == 0) this.fetchData();
    },

    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData();
        }
      } else if (this.filter.length == 0) this.fetchData();
    },

    filterPage() {
      if (this.pageNo.length > 0) {
        this.currentPage = this.pageNo;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async fetchData(txt) {
      this.$store.commit("loader/updateStatus", true);
      let request = `${indonesia_payment?.giftCardHistory}?page=${
        this.currentPage ?? 1
      }&q=${this.filter ?? ""}&filter=${txt ?? ""}`;
      try {
        const res = await axios.get(`${apiEndpoint}${request}`);
        if (res) {
          console.log(res.data.response.data);
          this.tableData = res?.data?.response;
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        this.$store.commit("toast/updateStatus"),
          {
            status: true,
            icon: "error",
            title: "Somthing went wrong",
          };
        this.$store.commit("loader/updateStatus", false);
      }
    },
  },

  watch: {
    currentPage: {
      handler: function (v) {
        if (v != "" && v != null) {
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    this.fetchData();
  },
};
