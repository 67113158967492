<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" id="expert">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-6">
                <div class="form-inline navbar-search">
                  <div class="input-group">
                    <input
                      v-on:keyup="search"
                      name="title"
                      class="form-control bg-light border-0 small"
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      v-model="filter"
                    />
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="searchFor">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table
                striped
                bordered
                :items="tableData.data"
                :per-page="0"
                :current-page="currentPage"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive="sm"
                class="table-bordered table-hover"
                :key="key"
              >
                <template v-slot:cell(member)="row">
                  <span v-if="row.item.member">
                    {{ row.item.member.fname }} {{ row.item.member.lname }}
                  </span>
                </template>
                <template v-slot:cell(value)="row">
                  <span v-if="row.item.gift_card">
                    {{ row.item.gift_card.value }}
                  </span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(usage)="row">
                  <span v-if="row.item.gift_card">
                    {{ row.item.gift_card.usage }}
                  </span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(code)="row">
                  <span v-if="row.item.gift_card">
                    {{ row.item.gift_card.code }}
                  </span>
                  <span v-else>---</span>
                </template>
              </b-table>
            </div>
            <template v-if="tableData.total > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      first-number
                      last-number
                      :total-rows="tableData.total"
                      :total-pages="tableData.total"
                      :per-page="tableData.per_page"
                    >
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../../mixins/request";
import giftHistoryMixin from "../../../../mixins/ModuleJs/gift-card-history";

export default {
  mixins: [MixinRequest, giftHistoryMixin],
  data() {
    return {
      title: "Gift Card History",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>
